@charset "utf-8";

// Define defaults for each variable.

$base-font-family: -Montserrat, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, apple-system, BlinkMacSystemFont, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

$base-font-size   : 16px !default;
$base-font-weight : 400 !default;
$small-font-size  : $base-font-size * 0.875 !default;
$base-line-height : 1.2 !default;
$sidebar_f_size   : 14px;
$spacing-unit     : 30px !default;

$text-color:       #fdfdfd !default;
$text-color-s:     rgb(200,255,255) !default;
$text-color-t:     #5a6170 !default;
$background-color: #060002 !default;
$bg-color-doc:     #ffffff !default;
$brand-color:      #8dc53e !default;
$accent-2-color:   #a99548 !default;
$accent-3-color:   #b87b4e !default;
$sidebar-color:    #a6b4cf !default;
$sidebar-hover:    #d8e2f5 !default;
$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    1000px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting"
;
