// color theme
$white: #ffffff;
$s_light: #efefef;
$light: #aaaaaa;
$normal: #555555;
$dark: #000000;

body {
  * {
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
  }
}
hr {
  margin-bottom: 15px;
  background: transparent;
  border: 1px solid $accent-3-color;
}
h1 {
  margin-top: 60px;
  text-align: center;
  font-size: 62px;
  font-weight: 700;
  color: $brand-color;
}
h2 {
  text-align: center;
  padding: 40px 0 20px;
  color: $accent-2-color;
  font-size: 64px;
  font-weight: 700;
}
h3 {
  padding: 40px 0 20px;
  font-size: 40px;
  font-weight: 700;
  color: $brand-color;
}
h4 {
  padding: 40px 0 20px;
  font-size: 30px;
  color: $accent-2-color;
}
h5 {
  font-size: 20px;
  color: $accent-2-color;
}
h6 {
  font-size: 16px;
  color: $accent-2-color;
}
p {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.6;
}
html,
body {
  position: relative;
  height: 100%;
  width: 100%;
}
body {
  background: $background-color;
  font-family: Monserrat, Arial, sans-serif;
  margin: 0;
  padding: 0;
  * {
    box-sizing: border-box;
  }
}
picture img {
  width: 100%;
}
.bg_img {
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.2;
}
.container {
  padding: 0 2%;
  margin: 0 auto;
}
.intro {
  width: 280px;
  margin: 30px auto;
  text-align: center;
  ul {
    margin: 0;
    text-align: left;
    font-size: 16px;
    padding: 10px;
    li {
      margin: 5px;
      color: $accent-3-color;
    }
  }
}
.transparent {
  background: transparent;
}
.white {
  background: #000;
  border-bottom: 1px solid $accent-3-color;
}
.main_header {
  position: fixed;
  top: 0;
  background: $background-color;
  box-sizing: border-box;
  z-index: 9999;
  width: 100%;
  height: max-content;
  padding: 8px 4%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .logo {
    &:hover {
      text-decoration: none;
      * {
        color: $brand-color;
      }
    }
    color: $accent-2-color;
    p {
      margin: 0;
      padding-top: 5px;
      text-decoration: none;
      font-weight: 500;
      font-size: 15px;
      letter-spacing: 2.2px;
      font-family: "Montserrat", sans-serif;
      color: $accent-2-color;
    }
  }

  .site_links {
    display: flex;
    align-items: center;
    a {
      color: $s_light;
      font-size: 16px;
      padding: 5px;
      &:hover {
        color: $light;
        text-decoration: none;
      }
    }
    .active {
      color: $brand-color;
    }
  }
}
.post-content {
  img {
    width: 100%;
  }
}
#services,
#about {
  padding-top: 130px;
}
#about {
  box-shadow: 2px 2px 30px 20px rgba(255, 245, 240, 0.1);
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.75)),
    url("/assets/images/abstract_blue_light_vector_background_148846.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.hidden {
  display: none;
}
.nav_main {
  position: absolute;
  right: 40px;
  top: 50px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  list-style: none;
  margin: 0 auto;
  padding: 3% 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
  li {
    display: block;
    text-align: right;
    padding: 11% 6vw;
    a {
      color: #fff;
      text-shadow: 5px 8px 16px #000;
    }
  }
  li:hover {
    background-color: $dark;
    box-shadow: 2px 3px 4px #333;
  }
}

/* FOOTER */
.site_footer {
  position: relative;
  background: #000;
  border-top: 1px solid #333;
  padding: 40px 15px;
  * {
    color: $white;
    text-decoration: none;
    list-style-type: none;
  }
  .wrapper {
    max-width: 1200px;
    margin: 0 auto;
  }
  p,
  a {
    margin: 0 0 5px;
    &::before {
      padding: 5px 10px 0 0;
    }
  }
  .col1 {
    * {
      text-align: center;
      text-decoration: none;
    }
  }
}

.author_img_box {
  display: block;
  width: 100%;
  height: fit-content;
  position: relative;
  .author_image {
    display: block;
    margin: auto;
    border: 3px solid $brand-color;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }
  .author_nick {
    margin: 0 auto;
    padding: 10px 0 10px 30px;
    width: max-content;
    background-image: url("/assets/images/favicon-32.png");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 24px auto;
    font-size: 20px;
    direction: ltr;
  }
}
@media (min-width: 600px) {
    h2 {
      font-size: 80px;
    }
  .footer_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    h3 {
      line-height: 1;
    }
    .col1 {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-content: center;
    }
  }
}

@media (min-width: 768px) {
  h1 {
    text-align: left;
    font-size: 100px;
  }
  .page {
    min-height: 85vh;
  }
  .intro_wrapper {
    display: block;
    position: relative;
    min-height: 100vh;
    .intro {
      width: 330px;
      position: absolute;
      left: 20%;
      * {
        text-align: left;
      }
    }
  }
  .services .inner ul li {
    grid-template-columns: 1fr;
    align-items: end;
  }
  .about {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  .team_item {
    grid-template-columns: 1fr 2fr;
    gap: 4rem;
  }
}
@media screen and (min-width: 1024px) {
  .intro {
    left: 15%;
    padding-top: 7%;
  }
  .services .inner ul li {
    grid-template-rows: max-content;
    align-items: start;
  }
  .inner {
    margin: 0 auto;
  }
  .filter {
    background: rgba(0, 0, 0, 0);
  }
  .about {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .container {
    max-width: 1000px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .container-fluid {
    width: 100%;
  }
}

.contact {
  margin-top: 10%;
}

@media (max-width: 800px) {
  .intro {
    .inner {
      margin: 30px auto;
    }
    h2 {
      letter-spacing: normal;
    }
  }
}
@media (max-width: 767px) {
  .main_header {
    .site_links,
    .logo {
      margin: 0 auto;
      a {
        padding: 5px 10px;
      }
    }
  }
  .intro {
    .inner {
      margin: 10px auto;
    }
    h2 {
      padding: 10px;
      font-size: 30px;
    }
    p {
      padding: 5px 20px;
      text-align: justify;
    }
  }
}
