.services {
  overflow: hidden;
  position: relative;
  z-index: 10;
  margin: 0 auto;
  padding: 20px 2%;
  h2 {
    margin-bottom: -90px;
  }
  h3 {
    padding-top: 130px;
  }
  .inner {
    width: 100%;
    max-width: 1300px;
    height: auto;
    ul {
      list-style-type: none;
      padding-left: 0;
      margin: 0;
    }
  }
  .site_links {
    text-align: center;
  }
  .about_icon {
    display: block;
    margin: 20px auto;
  }
}
.post-title {
  font-size: 36px;
}
.content_item {
  display: grid;
  gap: 20px;
  align-items: center;
  grid-template-rows: max-content;
  margin: 20px auto;
  img {
    padding: 10px;
    overflow: hidden;
  }
}

ul {
  font-weight: 300;
  line-height: 1.6;
  list-style: none;
  li::before {
    font-size: 20px;
    font-weight: 700;
    color: #a99548;
    margin-right: 5px;
    content: "✔";
  }
}

.about {
  p::before {
    content: "★";
    color: $accent-3-color;
    padding-right: 7px;
    vertical-align: bottom;
  }
  .about_serv {
    margin: 50px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-around;
    list-style-type: none;
    .about_serv_item {
      text-align: center;
      border-radius: 5px;
      padding: 8px;
      max-width: 140px;
      height: 100%;
      display: grid;
      grid-gap: 5px;
      align-content: space-between;
    }
    .serv_link:hover {
      box-shadow: 2px 2px 30px 10px rgba(255, 255, 255, 0.4);
      a {
        text-decoration: none;
        * {
          color: $brand-color;
        }
      }
    }
    li::before {
      content: "";
      display: none;
    }
    img {
      width: 100%;
    }
  }
}

.marked::before {
  content: "★";
  color: $accent-3-color;
  font-size: 20px;
  padding-right: 13px;
  vertical-align: bottom;
}
.md_card {
  img {
    width: 30%;
    float: left;
  }
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
@media (min-width: 375px) {
  .post-title {
    font-size: 42px;
  }
}
@media (min-width: 600px) {
  .post-title {
    font-size: 56px;
  }
  .md_card {
    img {
      margin-right: 10px;
      width: 20%;
    }
  }
}
@media (min-width: 800px) {
  .post-title {
    font-size: 76px;
  }
  .about {
    p::before {
      font-size: 24px;
      margin-left: -30px;
      padding-right: 13px;
    }
  }
  .content_item {
    grid-template-columns: 1fr 4fr;
    gap: 30px;
  }
  .md_card {
    img {
      width: 10%;
    }
  }
}
